a:-webkit-any-link {
  color: rgba(255, 255, 255, 0.7)
}

a {
  color: rgba(255, 255, 255, 0.7)
}

body {
  overflow-x: hidden;
  width: '100%';
}
